<template>
  <div class="flex flex-col sm:mt-10 items-center">
    <div class="text-3xl mt-10 sm:mt-0 font-bold self-center dark:text-white">Contact Us</div>
    <div class="w-full sm:w-5/6">
    <ContactUsForm />
    </div>
  </div>
</template>

<script>

import ContactUsForm from "../components/ContactUsForm";
export default {
  name: "ContactUs",
  components: { ContactUsForm }

}
</script>

<style scoped>
</style>
